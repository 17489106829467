import React from "react";
import { cloneDeep, orderBy } from "lodash";

const TrajectoryMap = Loader.loadBusinessComponent("MapComponent", "TrajectoryMap");
const ModalComponent = Loader.loadBaseComponent("ModalComponent");
const LabelValue = Loader.loadBaseComponent("LabelValue");
const ImageMovieMap = Loader.loadBusinessComponent("ImageMovieMap");

class TrajectoryView extends React.Component {
  constructor() {
    super();
    this.modalRef = React.createRef();
    this.state = {
      visible: false,
      data: {}
    };
    this.list = [];
  }
  componentDidMount() {}
  componentWillUnmount() {
    this.modalRef = null;
    this.list = null;
  }
  initData = () => {
    const { list } = this.props;
    let arr = orderBy(list, ["captureTime", "desc"]).map(v => {
      return {
        captureTime: v.captureTime,
        cameraId: v.cid,
        position: [v.longitude, v.latitude],
        deviceName: v.deviceName,
        title: v.deviceName,
        imgUrl: v[`${this.props.type}Url`] || v.url,
        _data: v
      };
    });
    return arr;
  };

  init = trajectory => {
    let list = this.initData();
    this.list = list;
    this.forceUpdate();
  };

  onClickCard = data => {
    const dataNew = cloneDeep(data);
    dataNew.cid = dataNew.cameraId;
    dataNew.imgUrl = dataNew.url;

    this.setState({ visible: true, data: dataNew });
  };

  onCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const data = this.state.data._data || {};
    const { type = "face" } = this.props;
    return (
      <div className="trajectory-map">
        <div className="trajectory-map-modal" ref={this.modalRef} />
        <TrajectoryMap onClickCard={this.onClickCard} init={this.init} data={this.initData()} type={type} />
        <ModalComponent
          getContainer={() => this.modalRef.current}
          className="trajectory-modal-layout"
          width={1000}
          title="详情"
          onCancel={this.onCancel}
          visible={visible}
          otherModalFooter={<></>}
        >
          <ModalContent data={data} onCancel={this.onCancel} />
        </ModalComponent>
      </div>
    );
  }
}

function ModalContent({ data = {}, onCancel }) {
  const { deviceName, address, captureTime, installationLocationDetail } = data;
  return (
    <>
      <div className="trajectory-modal-header">
        <LabelValue fontSize={14} label="抓拍地点" value={address || installationLocationDetail || deviceName} />
        <LabelValue fontSize={14} label="抓拍时间" value={Utils.formatTimeStamp(captureTime)} />
        <LabelValue fontSize={14} label="抓拍设备" value={deviceName || address} />
      </div>
      <ImageMovieMap key={data.id} data={data} beforeJumppage={onCancel} video={!data.cid} />
    </>
  );
}

export default TrajectoryView;
