(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("_"), require("ReactRouterDOM"), require("mobxReact"));
	else if(typeof define === 'function' && define.amd)
		define("resourceTrajectory", ["React", "_", "ReactRouterDOM", "mobxReact"], factory);
	else if(typeof exports === 'object')
		exports["resourceTrajectory"] = factory(require("React"), require("_"), require("ReactRouterDOM"), require("mobxReact"));
	else
		root["resourceTrajectory"] = factory(root["React"], root["_"], root["ReactRouterDOM"], root["mobxReact"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 